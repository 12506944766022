import {
  ClickAwayListener,
  styled,
  tooltipClasses,
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';
import { useState } from 'react';

export interface TooltipProps extends MUITooltipProps {
  type?: 'hover' | 'click';
  disablePortal?: boolean;
}

const defaultTooltipProps: Partial<TooltipProps> = {
  placement: 'top',
  disableHoverListener: false,
  disableTouchListener: false,
  arrow: true,
};

const Tooltip = styled(
  ({ className, children, type, disablePortal = true, ...props }: TooltipProps) => {
    const [open, setOpen] = useState(false);

    const clickProps = {
      ...defaultTooltipProps,
      disableHoverListener: true,
      disableTouchListener: true,
      open,
      onClick: () => setOpen(!open),
    };

    const tooltipProps = { ...(type === 'hover' ? defaultTooltipProps : clickProps), ...props };

    const toolTip = (
      <MUITooltip
        {...tooltipProps}
        classes={{ popper: className }}
        PopperProps={{
          disablePortal,
        }}
      >
        {/* this lets us apply style rules to the child element */}
        {{
          ...children,
          props: { ...children.props, className: `${children.props.className || ''} ${className}` },
        }}
      </MUITooltip>
    );
    switch (type) {
      case 'hover':
        return <>{toolTip}</>;
      case 'click':
      default:
        return <ClickAwayListener onClickAway={() => setOpen(false)}>{toolTip}</ClickAwayListener>;
    }
  }
)(({ theme, type }) => ({
  cursor: type === 'click' ? 'pointer' : 'inherit',
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.designTokens.colorPrimaryDarker,
    color: theme.designTokens.colorWhite,
    textAlign: 'left',
    borderRadius: '6px',
    fontSize: 14,
    fontWeight: theme.typography.body1.fontWeight,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing('12px', '16px', '14px', '16px'),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.designTokens.colorPrimaryDarker,
    height: '11px',
    width: '18px',
  },
}));

Tooltip.defaultProps = { ...defaultTooltipProps, type: 'click' };

export default Tooltip;
