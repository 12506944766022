import { ApolloError } from '@apollo/client';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendToDiscretionPage,
  sessionApplicationId,
  loadApplicationAttributes,
  sendToOnboarding,
  trackPageLoad,
  getIsLoadingAppContext,
  sendToBlockPage,
  sendToIntake,
  setUserExperience,
} from './session/sessionSlice';
import {
  FinalDecision,
  GetQuoteQuery,
  QuoteBlockStatus,
  useGetPackagesWithActiveQuoteQuery,
  useGetQuoteQuery,
} from 'generated/graphql';
import { get } from 'lodash';
import { processPackages } from './packages/packages.actions';
import {
  getActiveQuoteIds,
  getOriginalQuoteIds,
  packageOrder,
  selectedPackage,
} from './packages/packagesSlice';
import { emptyQuote, getActiveQuotesForPackages, quoteById } from './quotes/quotesSlice';
import { clearLoadingState, loadQuote, processQuote } from './quotes/quotes.actions';
import { loadCoverageGroupIdToTokenMap } from './coverageGroups/coverageGroupsSlice';
import { BLOCKED_PATH, PRINT_QUOTE_PATH, QUOTE_PATH } from 'shared/services/router';
import { catchError, LEVEL_CRITICAL, LEVEL_WARN } from './errors/errors.actions';
import { handleRouting } from 'shared/utils/routing-utils';
import {
  criticalStateSelector,
  errorStateSelector,
  isUnauthorizedOrForbiddenError,
} from './errors/errorsSlice';
import CrumbsContainer from 'containers/CrumbsContainer';
import { UserInfoContext } from 'shared/services/user-info/user-info-context';
import { envName } from 'environment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AccessDenied from 'Pages/access-denied/AccessDenied';
import { Outlet } from 'react-router-dom';
import { LoadSpinner, useExperience } from '@vouch/ui';
import { keyByUIToken } from './helpers';

const AppContext = () => {
  const dispatch = useDispatch();
  const stateApplicationId = useSelector(sessionApplicationId);
  const statePackageSlug = useSelector(selectedPackage);
  const packagesOrder = useSelector(packageOrder);
  const criticalState = useSelector(criticalStateSelector);
  const errorState = useSelector(errorStateSelector);
  const { quoteBlockingNewBusiness, quoteBlockingRenewalPremium } = useFlags();

  const location = useMemo(() => new URL(window.location.href), []);
  const paramSlug = location.searchParams.get('packageSlug');
  const [currentQuote] = useSelector(getActiveQuotesForPackages([paramSlug!]));

  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const { experience } = useExperience();

  useEffect(() => {
    analytics.identify({ quoteBlockingNewBusiness, quoteBlockingRenewalPremium, experience });
  }, [quoteBlockingNewBusiness, quoteBlockingRenewalPremium, experience]);

  useEffect(() => {
    dispatch(setUserExperience({ experience }));
  }, [dispatch, experience]);

  useEffect(() => {
    handleRouting({
      currentQuoteId: currentQuote.id,
      dispatch,
      navigate,
      setSearchParams,
      state,
      location,
      packagesOrder,
      paramSlug,
      pathname,
      statePackageSlug,
    });
  }, [
    currentQuote,
    dispatch,
    navigate,
    setSearchParams,
    state,
    location,
    paramSlug,
    pathname,
    packagesOrder,
    statePackageSlug,
  ]);

  const { data: userInfo } = useContext(UserInfoContext);

  useEffect(() => {
    dispatch(trackPageLoad({ pageName: pathname.substring(1) })); // substring to trim leading "/"
  }, [dispatch, pathname]);

  useGetPackagesWithActiveQuoteQuery({
    variables: { applicationId: stateApplicationId },
    skip: !!stateApplicationId && !!packagesOrder.length,
    onError: ({ message }: ApolloError) => {
      dispatch(
        catchError({
          level: queryErrorLevel(message),
          message: `GetPackages QueryError: ${message}`,
        })
      );
    },
    onCompleted: (data) => {
      const decision = get(data, ['application', 'finalDecision'], FinalDecision.NoDecision);
      const packages = get(data, ['application', 'packages'], []);
      let isRedirecting = false;

      if (packages.length === 0 || decision !== FinalDecision.Yes) {
        // Application is unlocked and doesn't have valid packages
        dispatch(
          data.application.applicationData.intake_application
            ? sendToIntake()
            : sendToOnboarding({ applicationId: stateApplicationId })
        );
        isRedirecting = true;
        return;
      }

      const [firstPackage] = packages;
      const pendingDiscretionDecisionId = firstPackage.activeQuote?.pendingDiscretionDecisionId;
      const blockType = data.application.quoteBlock?.blockType;
      const hasDiscretionId = !!pendingDiscretionDecisionId;
      const isRenewal = typeof data.application.renewalParentApplicationId == 'string';
      const canViewQuoteBlock =
        (!isRenewal && quoteBlockingNewBusiness) || (isRenewal && quoteBlockingRenewalPremium);

      if (hasDiscretionId) {
        dispatch(sendToDiscretionPage({ pendingDiscretionDecisionId }));
        isRedirecting = true;
      }

      if (
        data.application.quoteBlock?.status === QuoteBlockStatus.Pending &&
        (!userInfo?.isAdmin || envName === 'local') &&
        canViewQuoteBlock
      ) {
        dispatch(sendToBlockPage({ blockType }));
        navigate(BLOCKED_PATH);
        isRedirecting = true;
      }

      if (!isRedirecting) {
        dispatch(processPackages(packages));
      }

      const application = get(data, ['application'], {});
      dispatch(loadApplicationAttributes({ application: { ...application, packages: undefined } }));
    },
  });

  const processActiveQuote = (data: GetQuoteQuery, packageSlug: string) => {
    const quote = get(data, 'quote', emptyQuote);
    // TODO: Add some validation here to dispatch informative errors for quotes for $0, quotes with no products, etc
    dispatch(loadCoverageGroupIdToTokenMap({ products: quote.products }));
    dispatch(processQuote({ slug: packageSlug, quoteData: quote }));
  };

  const processOriginalQuote = (data: GetQuoteQuery, packageSlug: string) => {
    const quoteData = get(data, 'quote', emptyQuote);
    const quote = keyByUIToken(quoteData);
    dispatch(loadQuote({ slug: packageSlug, quote: quote, active: false }));
  };

  const isLoadingAppContext = useSelector(getIsLoadingAppContext);
  const [firstQuoteId, secondQuoteId] = useSelector(getActiveQuoteIds(packagesOrder));
  const [originalFirstQuoteId, originalSecondQuoteId] = useSelector(
    getOriginalQuoteIds(packagesOrder)
  );
  const [firstPackageSlug, secondPackageSlug] = packagesOrder;
  useGetQuote({
    quoteId: firstQuoteId,
    packageSlug: firstPackageSlug,
    onQuoteCompleted: (data: GetQuoteQuery) => {
      processActiveQuote(data, firstPackageSlug);
      if (firstQuoteId === originalFirstQuoteId) {
        processOriginalQuote(data, firstPackageSlug);
      }
    },
  });
  useGetQuote({
    quoteId: secondQuoteId,
    packageSlug: secondPackageSlug,
    onQuoteCompleted: (data: GetQuoteQuery) => {
      processActiveQuote(data, secondPackageSlug);
      if (secondQuoteId === originalSecondQuoteId) {
        processOriginalQuote(data, secondPackageSlug);
      }
    },
  });

  useGetQuote({
    quoteId: originalFirstQuoteId,
    packageSlug: firstPackageSlug,
    onQuoteCompleted: (data: GetQuoteQuery) => {
      processOriginalQuote(data, firstPackageSlug);
    },
    skip: firstQuoteId === originalFirstQuoteId,
  });
  useGetQuote({
    quoteId: originalSecondQuoteId,
    packageSlug: secondPackageSlug,
    onQuoteCompleted: (data: GetQuoteQuery) => {
      processOriginalQuote(data, secondPackageSlug);
    },
    skip: secondQuoteId === originalSecondQuoteId,
  });

  if (
    !criticalState &&
    !errorState?.isUnauthorized &&
    (isLoadingAppContext || ([QUOTE_PATH, PRINT_QUOTE_PATH].includes(pathname) && !currentQuote.id))
  ) {
    // TODO: Remove once quote page has skeletons
    return <LoadSpinner message="Loading your quote..." />;
  } else if (errorState?.isUnauthorized) {
    return <AccessDenied />;
  } else if (criticalState) {
    return <CrumbsContainer />;
  } else {
    return <Outlet />;
  }
};

const queryErrorLevel = (error: string | null) => {
  if (isUnauthorizedOrForbiddenError(error)) {
    return LEVEL_WARN;
  }
  return LEVEL_CRITICAL;
};

const useGetQuote = ({
  quoteId,
  packageSlug,
  onQuoteCompleted,
  skip = false,
}: {
  quoteId: string;
  packageSlug: string;
  onQuoteCompleted: (data: GetQuoteQuery) => void;
  skip?: boolean;
}) => {
  const dispatch = useDispatch();
  useGetQuoteQuery({
    variables: { id: quoteId },
    skip: !!useSelector(quoteById(quoteId)).id || !packageSlug || !quoteId || skip,
    onError: ({ message, graphQLErrors, clientErrors, networkError, extraInfo }: ApolloError) => {
      dispatch(clearLoadingState());
      dispatch(
        catchError({
          level: queryErrorLevel(message),
          message: `GetQuote QueryError: ${message}`,
          extraContext: {
            source: 'GraphQL: useGetQuoteQuery',
            quoteId,
            packageSlug,
            apolloData: {
              graphQLErrors,
              clientErrors,
              networkError,
              extraInfo,
            },
          },
        })
      );
    },
    onCompleted: onQuoteCompleted,
  });
};
export default AppContext;
