import { Container, Grid, Link, LoadSpinner, Typography, Footer } from '@vouch/ui';
import { getHasActiveBlock, getIsLoadingAppContext } from 'features/session/sessionSlice';
import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PATH } from 'shared/services/router';
import { getBlockType, sessionSelector } from 'features/session/sessionSlice';
import { BlockType } from 'generated/graphql';
import { catchError, LEVEL_CRITICAL } from 'features/errors/errors.actions';
import QuoteBlockedImg from '../../shared/assets/images/quote_blocked.png';

const RenewalCta = () => {
  const blockType = useSelector(getBlockType);

  switch (blockType) {
    case BlockType.ManualBlock:
    case BlockType.QbScaleRenewal:
    case BlockType.RenewalClientSizeBlock:
    case BlockType.RenewalCoverageGapBlock:
    case BlockType.RenewalPremiumBlock:
    case BlockType.CarrierPlatformQuoteBlock: {
      return (
        <>
          <Typography variant="body1" mb={6}>
            Your startup is unique, and we'd like the opportunity to review coverage options with
            you. Your dedicated account manager will email you shortly to schedule your meeting or
            share next steps. In the meantime, please feel to respond to your account manager with
            any additional questions. You may close this window at your convenience.
          </Typography>
        </>
      );
    }
    default: {
      return <>Error</>;
    }
  }
};

const NewBizCta = () => {
  const blockType = useSelector(getBlockType);

  switch (blockType) {
    case BlockType.ManualBlock:
    case BlockType.QbScaleNewBusiness:
    case BlockType.NewBizPremiumQuoteBlock:
    case BlockType.NewBizLifeSciencesBlock:
    case BlockType.CarrierPlatformQuoteBlock: {
      return (
        <Typography variant="body1" mb={6}>
          Your startup is unique, and we'd like the opportunity to review coverage options with you.
          An Insurance Advisor will email you shortly to schedule your consultation or share next
          steps.
          <br />
          <br />
          If your application is time sensitive, please{' '}
          <u>
            {
              <Link href="mailto:advisors@vouch.us" color="black">
                Talk to an Advisor.
              </Link>
            }
          </u>{' '}
          You may close this window at your convenience.
        </Typography>
      );
    }
    default: {
      return <>Error</>;
    }
  }
};

const AppCompleteDescription: FC = (): JSX.Element => {
  const isRenewal = useSelector(sessionSelector).isRenewal;

  return (
    <Grid item className="loading-title" sm={10} md={6.9}>
      <Typography variant="h2" mb={2}>
        Let's schedule a call to review your quote.
      </Typography>
      {isRenewal ? <RenewalCta /> : <NewBizCta />}
    </Grid>
  );
};

const BlockedPage: FC = (): JSX.Element | null => {
  const blockType = useSelector(getBlockType);
  const navigate = useNavigate();
  const loading = useSelector(getIsLoadingAppContext);
  const hasActiveBlock = useSelector(getHasActiveBlock);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasActiveBlock) {
      navigate(DEFAULT_PATH);
    }
    if (blockType != null && !Object.values(BlockType).includes(blockType)) {
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: `Unhandled block type: ${blockType}`,
        })
      );
    }
  });

  if (loading) {
    return <LoadSpinner message="Loading your quote..." />;
  }

  return (
    <Container>
      <Grid
        alignItems="center"
        className="loading-page"
        container
        data-testid="crumbs-page"
        justifyContent="center"
        textAlign="left"
        minHeight="100vh"
        flexDirection="column"
      >
        <Grid mt={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img alt="quote blocked" src={QuoteBlockedImg} style={{ maxWidth: '70%' }} />
        </Grid>
        <AppCompleteDescription />
        <Grid pt={5} pb={1}>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BlockedPage;
