import { useCallback, useEffect } from 'react';
import { Container, Grid, Typography, Skeleton, PrimaryButton, Box, useTheme } from '@vouch/ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  applicationReferralPartner,
  getProgramVersion,
  duplicatedApplicationId,
  sessionExpirationDate,
} from 'features/session/sessionSlice';
import { packageOrder } from 'features/packages/packagesSlice';
import { chatWidgetClicked } from 'features/eventTracking/eventTracking.actions';
import { selectPackage } from 'features/packages/packages.actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import QuoteCard from 'components/molecules/cards/QuoteCard';
import packagesConfig from './PackagesConfig';
import { getActiveQuotesForPackages } from 'features/quotes/quotesSlice';
import {
  formatAcceptedCoverages,
  getPackageTitle,
  getCurrentStatus,
  CurrentStatus,
  getTotalPremiumWithTaxesandFees,
} from 'shared/helpers';
import { footerMap, footerText } from 'shared/ui/tokenNames';
import { QUOTE_PATH } from 'shared/services/router';
import NotificationModal from 'components/molecules/modals/NotificationModal/NotificationModal';
import RecreatePackagesContainer from 'containers/RecreatePackagesContainer';
import DuplicateExpiredApplicationContainer from 'containers/DuplicateExpiredApplicationContainer';
import { useChat } from '@vouch/third-party/zendesk/widget';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TransactionFeeDisclosure } from 'components/atoms/buttons/text/transactionFeeDisclosure';

function PackagesPage(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const packagesOrder = useSelector(packageOrder);
  const activeQuotes = useSelector(getActiveQuotesForPackages(packagesOrder));
  const referralPartner = useSelector(applicationReferralPartner);
  const programVersion = useSelector(getProgramVersion);
  const duplicatedAppId = useSelector(duplicatedApplicationId);
  const extraFooterText = footerMap[packagesOrder[0]];
  const theme = useTheme();
  const flags = useFlags();

  const tracking = (path = '', openViaLink = false) => {
    dispatch(chatWidgetClicked({ path, openViaLink }));
  };
  const quoteExpirationString = activeQuotes ? activeQuotes[0]?.metadata?.expiresOn : '';
  const quoteExpirationDate = quoteExpirationString ? new Date(quoteExpirationString) : null;
  const applicationExpirationString = useSelector(sessionExpirationDate);
  const applicationExpirationDate = applicationExpirationString
    ? new Date(applicationExpirationString)
    : null;
  const currentStatus: CurrentStatus = getCurrentStatus(
    applicationExpirationDate,
    quoteExpirationDate
  );

  const viewQuote = useCallback(
    (packageSlug: string) => {
      dispatch(selectPackage({ packageSlug }));
      navigate(QUOTE_PATH + window.location.search);
    },
    [dispatch, navigate]
  );
  const { show: showChat } = useChat({ tracking, theme });

  useEffect(() => {
    if (activeQuotes.length === 1 && currentStatus === 'active') {
      viewQuote(packagesOrder[0]);
    }
  }, [activeQuotes, currentStatus, packagesOrder, viewQuote]);

  useEffect(() => {
    if (duplicatedAppId) {
      const url = new URL(window.location.href);
      url.searchParams.set('applicationId', duplicatedAppId);

      setSearchParams(url.search);
      window.location.reload();
    }
  }, [duplicatedAppId, setSearchParams]);

  return (
    <Container maxWidth={'lg'} sx={{ mt: 6 }}>
      <NotificationModal
        heading="Application Expired"
        isOpen={programVersion === '1.0'}
        buttonElem={
          <PrimaryButton
            data-testid={'app-expired-chat-btn'}
            variant="contained"
            onClick={showChat}
          >
            Talk to an Advisor
          </PrimaryButton>
        }
        text={`Since your application expired on ${applicationExpirationDate?.toLocaleDateString()}, one of our Insurance Advisors needs to verify that the information provided remains up-to-date.`}
        isLoading={false}
      />
      <NotificationModal
        heading="Packages Expired"
        isOpen={programVersion !== '1.0' && currentStatus === 'quote expired'}
        buttonElem={<RecreatePackagesContainer />}
        text={`Because the packages expired on ${quoteExpirationDate?.toLocaleDateString()}, we need to refresh your packages to access updated coverages and pricing.`}
        isLoading={false}
      />
      <NotificationModal
        heading="Application Expired"
        isOpen={programVersion !== '1.0' && currentStatus === 'application expired'}
        buttonElem={<DuplicateExpiredApplicationContainer />}
        text={`Because the application expired on ${applicationExpirationDate?.toLocaleDateString()}, we need you to confirm the answers.`}
        isLoading={false}
      />
      <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h2">Choose Your Insurance Package</Typography>
          <Typography>You can add, remove and customize coverages before checkout.</Typography>
        </Grid>

        <Grid container justifyContent="center">
          {activeQuotes.map((quote, index) => {
            const { acceptedCoverages, id, pricing } = quote;
            const packageSlug = packagesOrder[index];

            if (!id) {
              return (
                <Grid key={packageSlug}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      borderRadius: 2,
                      width: '400px',
                      height: '400px',
                      marginLeft: '12px',
                      marginRight: '12px',
                    }}
                  />
                </Grid>
              );
            } else {
              const packageName = getPackageTitle(packageSlug, referralPartner);
              const { description, recommended } = packagesConfig[packageSlug];
              const coverageList = formatAcceptedCoverages(acceptedCoverages);

              return (
                <Grid
                  item
                  sx={{ width: '400px', marginLeft: '12px', marginRight: '12px', mt: 2 }}
                  key={packageSlug}
                  data-testid={`select-package-${packageSlug}`}
                  order={packageSlug.match(/recommended/) ? 1 : 2}
                >
                  <QuoteCard
                    quoteName={packageName}
                    description={description}
                    recommended={recommended}
                    cost={getTotalPremiumWithTaxesandFees(pricing)}
                    coverageList={coverageList}
                    selectQuote={() => currentStatus !== 'quote expired' && viewQuote(packageSlug)}
                  />
                </Grid>
              );
            }
          })}
        </Grid>

        <Grid item xs={12} sx={{ mt: '8rem', textAlign: 'center' }}>
          {flags.chargeTransactionFees && (
            <Box mb={10}>
              <TransactionFeeDisclosure content="Transaction fees calculated at checkout" />
            </Box>
          )}
          <Typography variant="h4" component="h2" mb={1}>
            Have questions about what coverages you need?
          </Typography>
          <Typography variant="body1" mb={8}>
            Click on our chat button to talk to one of our licensed Insurance Advisors.
          </Typography>
          {extraFooterText && (
            <Typography variant="subtitle2" textAlign="left" pb={2}>
              {extraFooterText}
            </Typography>
          )}
          <Typography variant="subtitle2" pb={2} fontWeight="normal">
            {footerText}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PackagesPage;
