import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { envName } from 'environment';
import { State } from 'store';
import LogRocket from 'logrocket';
import rollbar from '@vouch/third-party/rollbar';
import { catchError, LEVEL_ERROR } from 'features/errors/errors.actions';
import { ProgramVersion } from 'shared/services/onboarding-funnel';
import { getIntakeClientUrl } from 'shared/services/intake-client';
import { BlockType } from 'generated/graphql';

const AI_INELIGIBLE_STATES = ['IL', 'NY', 'OR', 'PA'];
const AI_BUSINESS_QUESTION = 'primarily-ai-business';
const PRIMARY_LOCATION_CONTAINER = '2019-07-01--LOCATION_CONTAINER';
const PRIMARY_STATE_QUESTION = '2019-07-01--ADDRESS_STATE';
const REVENUE_QUESTIONS_CONTAINER = '2019-07-01--ANNUAL_REVENUE_CONTAINER';
const LTM_REVENUE_QUESTION = '2019-07-01--ANNUAL_REVENUE_CURRENT_CENTS';
const MIN_LTM_REVENUE_FOR_AI_COVERAGE = 5000000;

export interface SessionState {
  applicationId: string;
  companyId?: string;
  createdAt: string;
  duplicatedApplicationId?: string;
  expiresOn: string;
  experience: string;
  email?: string;
  friendlyName?: string;
  officerName?: string;
  hasAcceptedQuote: boolean;
  hasActiveDiscretion: boolean;
  hasActiveBlock: boolean;
  blockType?: BlockType;
  idToken: string;
  isEligibleForAICoverage: boolean;
  isLoadingAuth: boolean;
  isLoadingAppContext: boolean;
  isPendingRequest: boolean;
  isRenewal: boolean;
  isVouchUserInProd: boolean;
  loadingMessage: string;
  loginPortalUrl: string;
  market?: string;
  offer_code?: string;
  packageSlug?: string;
  partner?: string;
  referralPartner?: string;
  shaOnboardingFunnel?: ProgramVersion;
  segmentTestingEnabled: boolean;
}

const initialState: SessionState = {
  idToken: '',
  applicationId: '',
  createdAt: '',
  duplicatedApplicationId: '',
  expiresOn: '',
  experience: '',
  hasAcceptedQuote: false,
  hasActiveDiscretion: false,
  hasActiveBlock: false,
  isEligibleForAICoverage: false,
  isRenewal: false,
  isLoadingAuth: false,
  isLoadingAppContext: true,
  isPendingRequest: false,
  isVouchUserInProd: false,
  loadingMessage: '',
  loginPortalUrl: '',
  market: '',
  segmentTestingEnabled: false,
};

export const establishAuthSession = createAsyncThunk(
  'session/establishAuthSession',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (args: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getAccessTokenSilently,
      getIdTokenClaims,
      launchDarklyClient,
      redirectToLogin,
      segmentTesting,
    } = args;
    await getAccessTokenSilently({
      responseType: 'token id_token',
      scope: 'openid profile email',
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getIdTokenClaims().then((response: Record<string, any>) => {
          const email = response.email;
          LogRocket.identify(email, { email });
          launchDarklyClient.identify({ kind: 'user', email: email, key: email });
          dispatch(updateSessionToken({ idToken: response.__raw }));
          dispatch(setEmailAndAnalytics({ email }));
          dispatch(setSegmentTesting({ segmentTesting }));
        });

        // Poll Auth0 every 15 minutes to validate session
        // Auth0 Documentation
        // - session polling: https://auth0.com/docs/authenticate/login/configure-silent-authentication#:~:text=Poll%20with%20checkSession()
        // - error responses: https://auth0.com/docs/authenticate/login/configure-silent-authentication#:~:text=prompt%3Dnone%20parameter.-,Error,-responses
        const sessionPollInterval = setInterval(() => {
          getAccessTokenSilently({ ignoreCache: true })
            .then(() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              getIdTokenClaims().then((response: Record<string, any>) => {
                dispatch(updateSessionToken({ idToken: response.__raw }));
              });
            })
            .catch((e: ErrorEvent) => {
              clearInterval(sessionPollInterval);
              if (e.error === 'login_required') {
                redirectToLogin();
              }
            });
        }, 900000); // 15 minutes
      })
      .catch((e: ErrorEvent) => {
        if (e.error === 'login_required') {
          redirectToLogin();
        } else {
          if (window.location.href.includes('vouch.us'))
            dispatch(
              catchError({
                level: LEVEL_ERROR,
                message: `getAccessTokenSilently: ${e.message}`,
              })
            );
        }
      });
  }
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loadApplicationAttributes(state, { payload }) {
      const {
        address,
        applicationData,
        companyId,
        createdAt,
        duplicatedApplicationId,
        expiresOn,
        friendlyName,
        officerName,
        hasActiveDiscretion,
        referralPartner,
        shaOnboardingFunnel,
        type,
      } = payload.application;
      state.companyId = companyId;
      state.duplicatedApplicationId = duplicatedApplicationId;
      state.expiresOn = expiresOn;
      state.createdAt = createdAt;
      state.friendlyName = friendlyName;
      state.isEligibleForAICoverage =
        !!applicationData[AI_BUSINESS_QUESTION] &&
        applicationData[REVENUE_QUESTIONS_CONTAINER][LTM_REVENUE_QUESTION] >
          MIN_LTM_REVENUE_FOR_AI_COVERAGE &&
        !AI_INELIGIBLE_STATES.includes(
          applicationData[PRIMARY_LOCATION_CONTAINER][PRIMARY_STATE_QUESTION]
        );
      state.market = address?.state;
      state.officerName = officerName;
      state.referralPartner = referralPartner;
      state.shaOnboardingFunnel = shaOnboardingFunnel;
      state.hasActiveDiscretion = hasActiveDiscretion;
      // TODO: Gateway should really be returning a boolean instead of string, which is brittle for
      //  communicating whether something is a renewal or not
      state.isRenewal = type === 'RENEWAL';
      state.isLoadingAppContext = false;
    },
    requestCheckout(state, { payload }) {
      const { packageSlug, hasMonthly } = payload;
      const redirectLocation = `${hasMonthly ? 'billing' : 'checkout'}/${
        state.applicationId
      }/${packageSlug}`;
      const destination = getIntakeClientUrl(redirectLocation, { experience: state.experience });
      window.location.href = destination.href;
    },
    sendToIntake(state) {
      const destination = getIntakeClientUrl('', {
        legacyApplicationId: state.applicationId,
        experience: state.experience,
      });
      window.location.href = destination.href;
    },
    sendToDiscretionPage(state, { payload }) {
      const { pendingDiscretionDecisionId } = payload;

      const destination = getIntakeClientUrl('user-discretion', {
        discretion_id: pendingDiscretionDecisionId,
        experience: state.experience,
      });
      window.location.href = destination.href;
    },
    sendToBlockPage(state, { payload }) {
      const { blockType } = payload;
      state.hasActiveBlock = true;
      state.blockType = blockType;
    },
    sendToOnboarding(state, { payload }) {
      const params = new URLSearchParams({ experience: state.experience });

      if (payload.applicationId) {
        params.append('applicationId', payload.applicationId);
        params.sort();
      }
      window.location.href = `${state.loginPortalUrl}?${params.toString()}`;
    },
    setApplicationId(state) {
      const location = new URL(window.location.href);
      const applicationId = location.searchParams.get('applicationId')!;

      if (!applicationId) {
        window.location.href = state.loginPortalUrl;
      } else {
        state.applicationId = applicationId;
      }
    },
    setEmailAndAnalytics(state, { payload }) {
      const { email } = payload;
      state.email = email;
      state.isVouchUserInProd = envName === 'production' && email.endsWith('@vouch.us');

      analytics.identify(email, {
        userId: email,
        email,
        applicationId: state.applicationId,
      });

      rollbar.configure({
        payload: {
          person: {
            id: email, // required
            applicationId: state.applicationId,
            email,
          },
        },
      });

      return state;
    },
    setLoginUrl(state, { payload }) {
      const { loginPortalUrl } = payload;
      state.loginPortalUrl = loginPortalUrl;
    },
    trackPageLoad(state, { payload }) {
      const { pageName } = payload;
      const { applicationId, isVouchUserInProd } = state;
      if (!isVouchUserInProd) analytics.page(pageName, { applicationId });
    },
    setSegmentTesting(state, { payload }) {
      const { segmentTesting } = payload;
      state.segmentTestingEnabled = segmentTesting;
    },
    setUserExperience(state, { payload }) {
      const { experience } = payload;
      state.experience = experience;
    },
    updateHasAcceptedQuote(state) {
      state.hasAcceptedQuote = true;
    },
    updateSessionToken(state, action) {
      const { idToken } = action.payload;
      state.idToken = idToken;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(establishAuthSession.pending, (state) => {
      state.isLoadingAuth = true;
      state.loadingMessage = 'Authenticating...';
    });
    builder.addCase(establishAuthSession.fulfilled, (state) => {
      state.isLoadingAuth = false;
      state.loadingMessage = '';
    });
    builder.addCase(establishAuthSession.rejected, (state) => {
      state.isLoadingAuth = false;
      state.loadingMessage = '';
    });
  },
});

export const sessionSelector = (state: State): SessionState => state.session;
export const sessionApplicationId = (state: State): string => sessionSelector(state).applicationId;
export const sessionExpirationDate = (state: State): string => sessionSelector(state).expiresOn;
export const sessionApplicationDate = (state: State): string => sessionSelector(state).createdAt;
export const sessionUserExperience = (state: State): string => sessionSelector(state).experience;
export const duplicatedApplicationId = (state: State): string | undefined =>
  sessionSelector(state).duplicatedApplicationId;
export const idToken = (state: State): string => sessionSelector(state).idToken;
export const getIsEligibleForAICoverage = (state: State): boolean => {
  return sessionSelector(state).isEligibleForAICoverage;
};
export const isLoadingAuth = (state: State): boolean => sessionSelector(state).isLoadingAuth;
export const getIsLoadingAppContext = (state: State): boolean =>
  sessionSelector(state).isLoadingAppContext;
export const applicationReferralPartner = (state: State): string | undefined =>
  sessionSelector(state).referralPartner;
export const hasAcceptedQuote = (state: State): boolean => sessionSelector(state).hasAcceptedQuote;
export const getHasActiveDiscretion = (state: State): boolean =>
  sessionSelector(state).hasActiveDiscretion;
export const getHasActiveBlock = (state: State): boolean => sessionSelector(state).hasActiveBlock;
export const getBlockType = (state: State): BlockType | null | undefined =>
  sessionSelector(state).blockType;
export const getProgramVersion = (state: State): ProgramVersion | undefined =>
  sessionSelector(state).shaOnboardingFunnel;
export const getSessionInfo = (state: State): Record<string, string | undefined> => {
  const session = sessionSelector(state);
  return {
    email: session.email,
    companyId: session.companyId,
    friendlyName: session.friendlyName,
    market: session.market,
  };
};
export const {
  setApplicationId,
  setEmailAndAnalytics,
  loadApplicationAttributes,
  sendToDiscretionPage,
  sendToBlockPage,
  sendToOnboarding,
  requestCheckout,
  sendToIntake,
  setLoginUrl,
  setSegmentTesting,
  setUserExperience,
  trackPageLoad,
  updateHasAcceptedQuote,
  updateSessionToken,
} = sessionSlice.actions;
const sessionReducer = sessionSlice.reducer;
export default sessionReducer;
