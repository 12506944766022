import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useChat } from '@vouch/third-party/zendesk/widget';
import { BackAction, Box, Grid, PrimaryButton, Typography, useMediaQuery } from '@vouch/ui';
import theme from '@vouch/ui/src/components/theme/theme';
import ExpiresOn from 'components/atoms/form/ExpiresOn/ExpiresOn';
import BasicProduct from 'components/templates/basic-product';
import RecommendedProducts from 'components/templates/recommended-products';
import PricingSection from 'components/organisms/pricing-section/PricingSection';
import DiscretionFormContainer from 'containers/DiscretionFormContainer';
import EffectiveDateFieldContainer from 'containers/EffectiveDateFieldContainer';
import DuplicateExpiredApplicationContainer from 'containers/DuplicateExpiredApplicationContainer';
import { quoteIdOfLoadedCoverages } from 'features/coverageGroups/coverageGroupsSlice';
import { loadCoverages } from 'features/coverages/coverages.actions';
import { getCoverages, hasUnsavedChanges } from 'features/coverages/coveragesSlice';
import {
  backToPackages,
  chatWidgetClicked,
  quoteRePriced,
} from 'features/eventTracking/eventTracking.actions';
import { selectedPackage, packageOrder } from 'features/packages/packagesSlice';
import {
  activeQuoteBySlug,
  emptyQuote,
  getActiveQuotesForPackages,
  getIsLoading,
  originalQuoteBySlug,
} from 'features/quotes/quotesSlice';
import {
  getIsEligibleForAICoverage,
  getProgramVersion,
  getSessionInfo,
  hasAcceptedQuote,
  requestCheckout,
  sessionApplicationId,
  sessionExpirationDate,
  sessionSelector,
} from 'features/session/sessionSlice';
import { isBasic } from 'Pages/package/PackagesConfig';
import { PACKAGES_PATH } from 'shared/services/router';
import { footerMap, footerText } from 'shared/ui/tokenNames';

import './style.scss';
import { AICoverageBanner } from 'components/molecules/cards';
import NotificationModal from 'components/molecules/modals/NotificationModal/NotificationModal';
import { clearLoadingState, processQuote, setLoadingState } from 'features/quotes/quotes.actions';
import { getAcceptedItems } from 'features/helpers';
import { useUpdateQuoteMutation } from 'generated/graphql';
import { LEVEL_CRITICAL, catchError } from 'features/errors/errors.actions';
import { get } from 'lodash';
import { ALL_COVERAGES } from 'features/coverages/coverages.types';
import DownloadPdfContainer from 'containers/PdfDownloadContainer';
import { getCurrentStatus, CurrentStatus } from 'shared/helpers';

export const COVERAGE_CARD_WIDTH_PX = 224;
export const COVERAGE_CARD_MARGIN_PX = 20;
export const PRICING_SECTION_WIDTH_PX = 280;

const QuotePage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const packageSlug = useSelector(selectedPackage);
  const loadedQuoteId = useSelector(quoteIdOfLoadedCoverages);
  const isEligibleForAICoverage = useSelector(getIsEligibleForAICoverage);
  const isLoading = useSelector(getIsLoading);
  const quote = useSelector(activeQuoteBySlug(packageSlug!));
  const originalQuote = useSelector(originalQuoteBySlug(packageSlug!));
  const hasChanges = useSelector(hasUnsavedChanges);
  const lockQuote = useSelector(hasAcceptedQuote);
  const { market } = useSelector(getSessionInfo);
  const applicationId = useSelector(sessionApplicationId);
  const coverages = useSelector(getCoverages(ALL_COVERAGES));
  const packagesOrder = useSelector(packageOrder);
  const activeQuotes = useSelector(getActiveQuotesForPackages(packagesOrder));
  const flags = useFlags();
  const quoteExpirationString = quote.metadata.expiresOn;
  const quoteExpirationDate = quoteExpirationString ? new Date(quoteExpirationString) : null;
  const applicationExpirationString = useSelector(sessionExpirationDate);
  const applicationExpirationDate = applicationExpirationString
    ? new Date(applicationExpirationString)
    : null;
  const { aiCoverageBanner, mplRefile2 } = useFlags();
  const isRenewal = useSelector(sessionSelector).isRenewal;
  const smallScreen = useMediaQuery(theme.breakpoints.down(isBasic[packageSlug!] ? 'lg' : 'sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const programVersion = useSelector(getProgramVersion);
  const currentStatus: CurrentStatus = getCurrentStatus(
    applicationExpirationDate,
    quoteExpirationDate
  );
  const tracking = (path = '', openViaLink = false) => {
    dispatch(chatWidgetClicked({ path, openViaLink }));
  };
  const { show: showChat } = useChat({ tracking });

  useEffect(() => {
    if (loadedQuoteId === quote.id) return;
    dispatch(loadCoverages(quote));
  }, [dispatch, loadedQuoteId, quote]);

  const [repriceQuote, { loading: loadingReprice }] = useUpdateQuoteMutation({
    onError: (e) => {
      dispatch(clearLoadingState());
      dispatch(
        catchError({
          level: LEVEL_CRITICAL,
          message: `RepriceContainer MutationError: ${e.message}`,
        })
      );
    },
    onCompleted: (data) => {
      const requote = get(data, 'requote', emptyQuote);
      dispatch(quoteRePriced({ packageSlug }));
      dispatch(processQuote({ slug: packageSlug, quoteData: requote }));
    },
  });

  const requestReprice = () => {
    const { requestedCoverages, requestedLimits, requoteRequest } = getAcceptedItems(coverages);
    dispatch(setLoadingState());
    repriceQuote({
      variables: {
        applicationId,
        packageSlug,
        requestedCoverages,
        requestedLimits,
        requoteRequest,
      },
    });
  };

  const handleCheckout = () => {
    dispatch(
      requestCheckout({
        packageSlug,
        hasMonthly: !!quote.pricing.monthly,
      })
    );
  };

  const handleBackToPackages = () => {
    dispatch(backToPackages({ quoteCurrentlyViewing: packageSlug }));
    navigate(PACKAGES_PATH + window.location.search, { state: { backToPackages: true } });
  };

  const showBackAction = activeQuotes.length > 1;

  const extraFooterText = footerMap[packageSlug];

  return (
    <Grid
      container
      className={`quote-page ${isLoading ? 'editing-disabled' : lockQuote ? 'quote-locked' : ''} ${
        hasChanges ? 'reprice' : ''
      }`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingRight="1.25rem"
      paddingLeft="1.25rem"
    >
      <NotificationModal
        heading="Application Expired"
        isOpen={programVersion === '1.0'}
        buttonElem={
          <PrimaryButton
            data-testid={'app-expired-chat-btn'}
            variant="contained"
            onClick={showChat}
          >
            Talk to an Advisor
          </PrimaryButton>
        }
        text={`Since your application expired on ${applicationExpirationDate?.toLocaleDateString()}, one of our Insurance Advisors needs to verify that the information provided remains up-to-date.`}
        isLoading={false}
      />
      <NotificationModal
        heading="Application Expired"
        isOpen={programVersion !== '1.0' && currentStatus === 'application expired'}
        buttonElem={<DuplicateExpiredApplicationContainer />}
        text={`Because the application expired on ${applicationExpirationDate?.toLocaleDateString()}, we need you to confirm the answers.`}
        isLoading={false}
      />
      <Grid
        sx={{
          maxWidth:
            3 * (COVERAGE_CARD_WIDTH_PX + COVERAGE_CARD_MARGIN_PX) +
            PRICING_SECTION_WIDTH_PX +
            COVERAGE_CARD_MARGIN_PX,
        }}
      >
        <Grid container pb="1.25rem">
          <Grid
            item
            display="flex"
            justifyContent={showBackAction ? 'space-between' : 'right'}
            width="100%"
          >
            {showBackAction && <BackAction text="Back" onClick={handleBackToPackages} />}
            <DownloadPdfContainer />
          </Grid>
        </Grid>

        <Grid container pb="2.5rem">
          <Grid item>
            <Typography variant="h2">Your Insurance Package</Typography>
            <EffectiveDateFieldContainer availableEffectiveDate={quote.availableEffectiveDate} />
            {mplRefile2 && !isRenewal && (
              <ExpiresOn
                quoteExpirationString={quoteExpirationString}
                applicationExpirationString={applicationExpirationString}
              />
            )}
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            flexDirection: smallScreen ? 'column' : 'row',
          }}
        >
          <Box
            sx={
              smallScreen
                ? {
                    marginRight: '0px',
                    maxWidth: '100%',
                  }
                : {
                    marginRight: '1.25rem',
                    maxWidth: 3 * (COVERAGE_CARD_WIDTH_PX + COVERAGE_CARD_MARGIN_PX),
                  }
            }
          >
            {isBasic[packageSlug!] ? (
              <BasicProduct product={quote.products.BOP || quote.products.CPP} />
            ) : (
              <>
                {
                  <RecommendedProducts
                    market={market}
                    products={quote.products}
                    originalProducts={originalQuote.products}
                  />
                }
                {!smallScreen && isEligibleForAICoverage && aiCoverageBanner && (
                  <Grid container>
                    <Grid item pt={2} pb={2}>
                      <AICoverageBanner />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Box>

          <Grid
            item
            id="pricing-section-container"
            sx={{
              display: smallScreen ? 'flex' : 'block',
              justifyContent: 'right',
            }}
          >
            <Grid
              item
              sx={
                smallScreen
                  ? {
                      top: 0,
                      position: 'relative',
                      marginTop: '1.25rem',
                      marginBottom: '1.25rem',
                      width: isBasic[packageSlug!] && !isMobile ? PRICING_SECTION_WIDTH_PX : '100%',
                    }
                  : {
                      top: '1rem',
                      position: 'sticky',
                      marginTop: '0px',
                      marginBottom: '0px',
                      width: PRICING_SECTION_WIDTH_PX,
                    }
              }
            >
              <PricingSection
                handleCheckout={handleCheckout}
                hasChanges={hasChanges}
                pricing={quote.pricing}
                chargeTransactionFees={flags.chargeTransactionFees}
                requestReprice={requestReprice}
                loading={loadingReprice}
              />

              {smallScreen &&
                !isBasic[packageSlug!] &&
                isEligibleForAICoverage &&
                aiCoverageBanner && (
                  <Grid container>
                    <Grid item pt={2} pb={2}>
                      <AICoverageBanner />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Box>

        <Grid
          className="section-divider"
          xs={12}
          sx={{
            borderBottom: `1px solid ${theme.designTokens.colorSecondary}`,
            marginTop: '1rem',
          }}
          item
        />

        <Grid container className="discretion-container" mt={1}>
          <Grid item>
            <DiscretionFormContainer />
          </Grid>
        </Grid>

        <Grid container className="footer-container" justifyContent="center">
          <Grid item xs={10} sm={10} xl={8}>
            {extraFooterText && (
              <Typography className="footer" variant="subtitle2">
                {extraFooterText}
              </Typography>
            )}
            <Typography className="footer" variant="subtitle2" fontWeight="normal">
              {footerText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuotePage;
